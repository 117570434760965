<template>
  <div class="main">
    <div class="content"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.txt {
  // max-width: 250px;
  h1 {
    font-size: 4rem;
    margin: 0.2rem 0;
  }
}
</style>
